$(document).ready(function () {
  if ($('.frame').data('page') == 'index') {

    const htmlElem = document.documentElement;
    document.addEventListener('mousemove', onDocumentMousemove);
    htmlElem.style.setProperty('--circle', '10px');

    function onDocumentMousemove(evt) {
      $('.ani-mask').css('opacity', '1');
      htmlElem.style.setProperty('--x', `${evt.clientX}px`);
      htmlElem.style.setProperty('--y', `${evt.clientY}px`);
    };
    document.getElementById('ima_dash').addEventListener('mouseover', function () {
      htmlElem.style.setProperty('--circle', '120px');
    });
    document.getElementById('ima_dash').addEventListener('mouseout', function () {
      htmlElem.style.setProperty('--circle', '60px');
    });

    const indexAn = gsap.timeline();
    indexAn
      .from(".index-main-content", { xPercent: -150, duration: 1 })
      .from("#index_title_line_mask", { x: -100 , duration: 0.5, ease: "power3.inOut" })
      .from("#index_title_line", { scale: 1.1, duration: 0.3, ease: "elastic.out(1,0.3)" })
      .from(".index-title-btns", { yPercent: -100, opacity: 0, ease: "elastic.out(1, 0.35)" })

    let mm = gsap.matchMedia();
    mm.add("(max-width: 800px)", () => {
      let indexMain_tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".index-main",
          start: "bottom 97%",
          end: "bottom center",
          scrub: 1,
          // pin: true,
          // markers: true,
        }
      });
      indexMain_tl
        .to(".frame", { className: "frame ani-end" }, 0)
        .to(".body", { className: "body bg-primary" }, '<-=0')

      let indexFeature_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#index_feature",
          start: "top top",
          end: '+=800%',
          scrub: 0.5,
          // markers: true,
        }
      });
      indexFeature_tl
    });

    // min-width 801
    mm.add("(min-width: 801px), (orientation: landscape)", () => {
      let indexMain_tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".index-main",
          start: "bottom 97%",
          end: "bottom center",
          scrub: 1,
          // pin: true,
          // markers: true,
        }
      });
      indexMain_tl
        .to(".frame", { className: "frame ani-end" })
        .to("#ima_circle", { scale: 0 })
        .fromTo(".body", { background: "#F6F7F8" }, { background: "#F6F7F8" }, '<-=0')
        .to(".index-main-ani-outer", { duration: 0.3, className: "index-main-ani-outer leave-main" }, '<-=0')

      let indexFeature_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#index_feature",
          start: "top top",
          end: '+=900%',
          scrub: 1,
          pin: true,
          // markers: true,
        }
      });
      indexFeature_tl
        .fromTo("#feature_focus", { opacity: 0, xPercent: -10 }, { opacity: 1, xPercent: 0 })
        .from(".index-feature-imgs", { opacity: 0 }, '<-=0')
        .from(".index-feature-img-text", { scale: 0, opacity: 0 }, '<-=0')
        .from("#f1_search_web", { duration: 0.3, scale: 0, ease: "power3.inOut" }, '<-=0')
        .from(".f1-search", { opacity: 0, duration: 0.3, stagger: 0.3 }, '<-=0')
        .from("#f1_list, #f1_seo", { delay: 0.3, duration: 0.3, scale: 0 }, '<-=0')
        .to("#f1_search_web, #f1_list, #f1_seo", { delay: 0.3, duration: 0.3, scale: 0, opacity: 0 })
        .to(".index-feature-1 .text", { xPercent: 400 }, '<-=0')
        .to("#feature_focus", { xPercent: 150 }, '<-=0')
        .from(".index-feature-2 .text", { xPercent: -400, opacity: 0 }, '<-=0')
        .to(".index-feature-2", { opacity: 1 }, '<-=0')
        .from("#f2_1, #f2_2, #f2_3", { duration: 0.3, scale: 0 }, '<-=0')
        .to(".index-feature-img.index-feature-2", { delay: 0.2, className: "index-feature-img index-feature-2 line-ani" }, '<-=0')
        .from("#f2_check, #f2_ux, #f2_ux_bg", { delay: 0.1, duration: 0.3, scale: 0 }, '<-=0')
        .to("#f2_1, #f2_2, #f2_3, #f2_ux, #f2_ux_bg, #f2_check", { duration: 0.3, scale: 0 })
        .to(".index-feature-2", { opacity: 0 }, '<-=0')
        .to("#feature_focus", { xPercent: 300 }, '<-=0')
        .to(".index-feature-2 .text", { xPercent: 400 }, '<-=0')
        .from(".index-feature-3 .text", { xPercent: -400, opacity: 0 }, '<-=0')
        .to(".index-feature-3", { opacity: 1 }, '<-=0')
        .from("#f3_main, #f3_user, #f3_item_m, #f3_item_lg", { duration: 0.3, scale: 0, ease: "power3.inOut" }, '<-=0')
        .from("#f3_btn1, #f3_btn2", { duration: 0.3, scale: 0, ease: "power3.inOut" })
        .to(".index-feature-3 .text", { delay: 0.1, xPercent: 400 })
        .to("#f3_main, #f3_user, #f3_item_m, #f3_item_lg, #f3_btn1, #f3_btn2", { delay: 0.1, duration: 0.3, scale: 0, opacity: 0 }, '<-=0')
        .to(".index-feature-4", { delay: 0.1, duration: 0.3, opacity: 1 }, '<-=0')
        .to("#feature_focus", { duration: 0.3, xPercent: 450 }, '<-=0')
        .from(".index-feature-4 .text", { xPercent: -400 }, '<-=0')
        .from("#f4_main, #f4_chart", { duration: 0.3, scale: 0, opacity: 0 }, '<-=0')
        .from(".f4-chart-line", { delay: 0.2, duration: 0.3, opacity: 0, scaleY: 0, stagger: 0.1 })
        .from("#f4_item, #f4_item2", { delay: 0.3, duration: 0.3, scale: 0, opacity: 0 }, '<-=0')
        .to("#feature_imgs", { duration: 0.3, opacity: 0, scale: 0 }, '<+=1')
      let indexShowcases_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#index_showcases",
          start: "top center",
          end: "center center",
          scrub: 1,
          // once: true,
          // markers: true,
        }
      });
      indexShowcases_tl
        .from(".index-showcases-bg", { scale: 0 })
        .from(".index-showcases-item[data-index=item_1]", { scale: 0, xPercent: 100, yPercent: 100 })
        .from(".index-showcases-item[data-index=item_2]", { scale: 0, xPercent: -100, yPercent: 100 }, '<-=0')
        .from(".index-showcases-item[data-index=item_3]", { scale: 0, xPercent: 100, yPercent: 10 }, '<-=0')
        .from(".index-showcases-item[data-index=item_4]", { scale: 0, xPercent: -100, yPercent: 10 }, '<-=0')
        .from(".index-showcases-item[data-index=item_5]", { scale: 0, xPercent: 100, yPercent: -100 }, '<-=0')
        .from(".index-showcases-item[data-index=item_6]", { scale: 0, xPercent: -100, yPercent: -100 }, '<-=0')

    })

    mm.add("all", () => {
      let indexServices_tl = gsap.timeline({
          scrollTrigger: {
          trigger: "#index_services",
          start: "top top",
          scrub: 1,
          pin: true,
          // markers: true,
        }
      });
      indexServices_tl
      .fromTo(".services-img-main img", { yPercent: 10, opacity: 0 }, { yPercent: 0, opacity: 1 })
      .from("[data-service-name=web]", { xPercent: 180, yPercent: 70, scale: 0, opacity: 0 }, '<-=0')
      .from("[data-service-name=system]", { xPercent: -150, yPercent: 150, scale: 0, opacity: 0 }, '<-=0')
      .from("[data-service-name=social]", { xPercent: 150, yPercent: -150, scale: 0, opacity: 0 }, '<-=0')
      .from("[data-service-name=plus]", { xPercent: -180, yPercent: -130, scale: 0, opacity: 0 }, '<-=0')
      .from(".item-block", { scale: 0, opacity: 0 })
      .from(".service-web-box", { duration: 0.3, scale: 0, y: 20, stagger: 0.5, ease: 'back.out(1.5)' }, '<-=0')
      .from("#service_web_line", { duration: 0.3, scaleX: 0, ease: 'back.out(1.5)' }, '<-=0')
      .from("#service_system_box2", { duration: 0.3, x: 10, y: 15, ease: 'back.out(1.5)' })
      .from("#service_system_box1", { duration: 0.3, x: -10, y: -15, ease: 'back.out(1.5)' }, '<-=0')
      .from("#service_system_gear", { duration: 0.3, y: 12, x: -5, ease: 'back.out(1.5)' })
      .from("#service_social_line", { duration: 0.3, y: 17, x: 25, ease: 'back.out(1.1)' }, '<-=0')
      .from("#service_social_fb", { duration: 0.3, y: -18, x: 17, ease: 'back.out(1.1)' }, '<-=0')
      .from("#service_social_google", { duration: 0.3, y: 8, x: -13, ease: 'back.out(1.1)' }, '<-=0')
      .from("#service_plus_add", { duration: 0.3, scale: 0 })
      .from("#service_plus_bg", { duration: 0.3, y: 5, x: 5, ease: 'back.out(1.1)' }, '<-=0')
      .from(".service-plus-show", { duration: 0.3, opacity: 0, y: 5, ease: 'back.out(1.5)', stagger: 0.3 }, '<-=0')
      .to("#services_imgs_circle", { delay: 0.5, opacity: 1 })
      .to(".index-main-ani-outer", { opacity: 0, zIndex: -1 }, '<-=0')

      let indexSolution_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#index_solution",
          start: "top bottom",
          end: "bottom bottom",
          scrub: 1,
          // once: true,
          // markers: true,
        }
      });
      indexSolution_tl
        .to(".body", { backgroundColor: "#EAF0F5" })
        .from("#index_solution_line", { scaleX: 0 }, '<-=0')
        .from("#index_solution_text", { opacity: 0 })
        .from("[data-solution-name=light] .item-img", { duration: 0.8, scale: 0.3, ease: "back.out(1.7)" })
        .from("[data-solution-name=plus] .item-img", { delay: 0.3, duration: 0.8, scale: 0.3, ease: "back.out(1.7)" }, '<-=0')
        .from("[data-solution-name=premium] .item-img", { delay: 0.6, duration: 0.8, scale: 0.3, ease: "back.out(1.7)" }, '<-=0')
        .to(".body", { background: "#F6F7F8" })

      let indexContact_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#index_contact",
          start: "center bottom",
          end: "center center",
          scrub: 1,
          // once: true,
          // markers: true,
        }
      });
      indexContact_tl
        .from("#contact_hand", { duration: 0.7, x: -100, y: -100, ease: "back.out(1.2)" })
        .from(".ic-item", { duration: 0.3, opacity: 0, scale: 0, stagger: 0.5, ease: "back.out(1.3)" }, '<-=0')
        .from("#index_C_hold", { duration: 0.3, opacity: 0, ease: "back.out(1.7)" })
        .fromTo("#keyboard_enter_top", { y: 8 }, { y: 13})
        .from("#keyboard_enter", { duration: 0.5, opacity: 0.5, ease: "back.out(1.7)" })
        .from("#hand_pad", { duration: 0.6, x: 100, y: 30})
        .from(".index-contact-main", { delay: 0.5, duration: 1, opacity: 0, scale: 0, y: 50, x: -50, ease: "back.out(1.7)" })
        .from(".c-item-show", { duration: 0.5, opacity: 0, scale: 0, stagger: 0.3, ease: "back.out(1.7)" })
        .from(".arrow-right", { delay: 0.3, duration: 0.5, opacity: 0, x: -20, stagger: 0.5 })
        .to("#contact_hand", { duration: 0.5, x: -10, y: -10})
        .to("#keyboard_enter_top", { y: 9 }, '<-=0')

    });

    $("#index_more_btn").on("click", function () {
      var mainH
      if ($(window).width() > 801) {
        var mainH = $('#index_main').height() + 1000;
      }else {
        var mainH = $('#index_main').height();
      }
      gsap.to(window, { duration: 0.3, scrollTo: mainH });
    });

  };
});

$(document).ready(function () {
  if ($('.frame').data('page') == 'service') {
    const serviceBannerAni = gsap.timeline();
    serviceBannerAni
      .from(".service-hand-img", { delay: 0.1, duration: 0.5, y: 50, x: 100, ease: 'circ.out' })
      .from("#serviceBnBox1", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from("#serviceBnBox2", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from("#serviceBnSwitch", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from("#serviceBnSwitch_btn", { duration: 0.3, x: 25, ease: 'circ.out'})
      .from("#serviceBnSwitch_bg", { duration: 0.3, ease: 'circ.out', attr: { fill: '#C4DCEE',}})
      .from(".service_bn_arrow", { delay: 0.5, duration: 0.5, opacity: 0, y: -20, stagger: 0.5, repeat: -1, repeatDelay: 3, })

    let mm = gsap.matchMedia();
    mm.add("(max-width: 800px)", () => {
      let serviceSteps_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#serviceSteps",
          start: "top center",
          end: 'bottom bottom',
          scrub: 1,
          // markers: true,
        }
      });
      serviceSteps_tl
        .from("[data-step='1'] .step-img", { scale: 0, ease: 'back.out(1.5)' })
        .from("[data-step='1'] .step-text", { opacity: 0, x: -100, ease: 'back.out(1.5)' }, '<-=0')
        .from(".step_1_box", { delay: 0.1, duration: 0.3, opacity: 0, scale: 0, x: -10, ease: 'back.out(1.5)' }, '<-=0')
        .from(".step_1_line1", { delay: 0.1, duration: 0.3, opacity: 0, x: -10, ease: 'back.out(1.5)' }, '<-=0')
        .from(".step_1_line2", { delay: 0.1, duration: 0.3, opacity: 0, x: -10, ease: 'back.out(1.5)' }, '<-=0')
        .from("[data-step='2'] .step-img", { scale: 0, ease: 'back.out(1.5)' })
        .from("[data-step='2'] .step-text", { opacity: 0, x: -100, ease: 'back.out(1.5)' }, '<-=0')
        .from("#step_2_list_bg", { delay: 0.1, duration: 0.3, opacity: 0, x: 30, ease: 'back.out(1.5)' }, '<-=0')
        .from("#step_2_list", { delay: 0.1, duration: 0.3, opacity: 0, x: 30, ease: 'back.out(1.5)' }, '<-=0')
        .from("[data-step='3'] .step-img", { scale: 0, ease: 'back.out(1.5)' })
        .from("[data-step='3'] .step-text", { opacity: 0, x: -100, ease: 'back.out(1.5)' }, '<-=0')
        .from("[data-step='4'] .step-img", { scale: 0, ease: 'back.out(1.5)' })
        .from("[data-step='4'] .step-text", { opacity: 0, x: -100, ease: 'back.out(1.5)' }, '<-=0')
        .from("[data-step='5'] .step-img", { scale: 0, ease: 'back.out(1.5)' })
        .from("[data-step='5'] .step-text", { opacity: 0, x: -100, ease: 'back.out(1.5)' }, '<-=0')
    });

    mm.add("(min-width: 801px)", () => {
      let serviceSteps_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#serviceSteps",
          start: "top top",
          end: '+=500%',
          scrub: 1,
          pin: true,
          // markers: true,
        }
      });
      serviceSteps_tl
        .from("[data-step='1'] .step-img", { scale: 0, ease: 'back.out(1.5)' })
        .from("[data-step='1'] .step-text", { opacity: 0, y: 100, ease: 'back.out(1.5)' }, '<-=0' )
        .from(".step_1_box", { delay: 0.1, duration: 0.3, opacity: 0, scale: 0, y: -10, ease: 'back.out(1.5)' }, '<-=0' )
        .from(".step_1_line1", { delay: 0.1, duration: 0.3, opacity: 0, y: -10, ease: 'back.out(1.5)' }, '<-=0' )
        .from(".step_1_line2", { delay: 0.1, duration: 0.3, opacity: 0, y: -10, ease: 'back.out(1.5)' }, '<-=0' )
        .from("[data-step='2'] .step-img", { scale: 0, ease: 'back.out(1.5)' })
        .from("[data-step='2'] .step-text", { opacity: 0, y: -100, ease: 'back.out(1.5)' }, '<-=0')
        .from("#step_2_list_bg", { delay: 0.1, duration: 0.3, opacity: 0, y: 30, ease: 'back.out(1.5)' }, '<-=0' )
        .from("#step_2_list", { delay: 0.1, duration: 0.3, opacity: 0, y: 30, ease: 'back.out(1.5)' }, '<-=0' )
        .from("[data-step='3'] .step-img", { scale: 0, ease: 'back.out(1.5)' })
        .from("[data-step='3'] .step-text", { opacity: 0, y: 100, ease: 'back.out(1.5)' }, '<-=0')
        .from("[data-step='4'] .step-img", { scale: 0, ease: 'back.out(1.5)' })
        .from("[data-step='4'] .step-text", { opacity: 0, y: -100, ease: 'back.out(1.5)' }, '<-=0')
        .from("[data-step='5'] .step-img", { scale: 0, ease: 'back.out(1.5)' })
        .from("[data-step='5'] .step-text", { opacity: 0, y: 100, ease: 'back.out(1.5)' }, '<-=0')
    });

    // All media width
    mm.add("all", () => {
      gsap.timeline().to("#service_system_gear", { duration: 8, rotation: 360, repeat: -1 })

      let serviceItems_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#serviceItems",
          start: "top center",
          end: "bottom 90%",
          scrub: 1,
          // markers: true,
        }
      });
      serviceItems_tl
        .from("#service_web_img", { duration: 0.3, scale: 0, y: 20, ease: 'back.out(1.5)' })
        .from(".service-web-box", { duration: 0.3, scale: 0, y: 20, stagger: 0.5, ease: 'back.out(1.5)' }, '<-=0')
        .from("#service_web_line", { duration: 0.3, scaleX: 0, ease: 'back.out(1.5)' }, '<-=0')
        .from("#service_system_box2", { duration: 0.3, x: 10, y:15, ease: 'back.out(1.5)' })
        .from("#service_system_box1", { duration: 0.3, x: -10, y: -15, ease: 'back.out(1.5)' }, '<-=0')
        .from("#service_system_gear", { duration: 0.3, y: 12, x:-5, ease: 'back.out(1.5)' })
        .from("#service_social_line", { duration: 0.3, y: 17, x: 25, ease: 'back.out(1.1)' }, '<-=0')
        .from("#service_social_fb", { duration: 0.3, y: -18, x: 17, ease: 'back.out(1.1)' } , '<-=0')
        .from("#service_social_google", { duration: 0.3, y: 8, x: -13, ease: 'back.out(1.1)' }, '<-=0')
        .from("#service_plus_add", { duration: 0.3, scale: 0 })
        .from("#service_plus_bg", { duration: 0.3, y: 5, x: 5, ease: 'back.out(1.1)' }, '<-=0')
        .from(".service-plus-show", { duration: 0.3, opacity: 0, y: 5, ease: 'back.out(1.5)', stagger: 0.3 }, '<-=0')
        .to(".service-items-bg", { scale: 1.5 }, '0')

      let textAniService_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#textAniService",
          start: "top bottom",
          end: 'bottom top',
          scrub: 1,
          // markers: true,
        }
      });
      textAniService_tl
        .from("#textAniService", { color: '#4996C6', xPercent: -50 })

      let textAniSolution_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#textAniSolution",
          start: "top bottom",
          end: 'bottom top',
          scrub: 1,
        }
      });
      textAniSolution_tl
        .to("#textAniSolution", { color: '#C4DCEE', xPercent: -50 })

      let serviceContact_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#serviceContact",
          start: "center bottom",
          end: '60% center',
          scrub: 1,
          // markers: true,
        }
      });
      serviceContact_tl
        .from("#sc_main_chart", { duration: 0.5, scale: 0, x: 50, ease: 'back.out(1.5)' })
        .from("#sc_second_chart", { duration: 0.5, scale: 0, x: -50, ease: 'back.out(1.5)' }, '<-=0')
        .from("#sc_third_chart", { duration: 0.5, scale: 0, x: 50, ease: 'back.out(1.5)' }, '<-=0')
        .from(".service-content-box", { delay: 0.2, duration: 0.5, opacity: 0, scale: 0, stagger: 0.5, })
        .from(".arrow-right", { delay: 0.3, duration: 0.5, opacity: 0, x: -20, stagger: 0.5, })
    });
  }
});

import Cookies from 'js-cookie';
import 'core-js';
import Lenis from '@studio-freight/lenis';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import 'public/main'
import 'public/home'
import 'public/services'
import 'public/about'
import 'public/showcases'
import 'public/contact'
import 'public/news'
import 'public/style.scss'

import Rails from "@rails/ujs"
Rails.start();

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;
window.Lenis = Lenis;
window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;

const lenis = new Lenis({
  duration: 1.2,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t))
});

function raf(time) {
  lenis.raf(time);
  ScrollTrigger.update();
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

$(document).ready(function () {
  if ($('.frame').data('page') == 'about') {
    const aboutBannerAni = gsap.timeline();

    let mm = gsap.matchMedia();
    mm.add("(max-width: 800px)", () => {

    });

    mm.add("(min-width: 801px)", () => {

    });

    // All media width
    mm.add("all", () => {

      let aboutMission_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#aboutMission",
          start: "top center",
          end: "bottom 90%",
          scrub: 1,
          // markers: true,
        }
      });
      aboutMission_tl
        .to(".about-mission-bg", { scale: 1.5 })
        .from(".about-mission-circle", { scale: 0.9, ease: 'back.out(1.5)' })
        .from(".about-mission-item", { duration: 0.5, opacity: 0, x: -30, stagger: 0.5, })

      let aboutVision_tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#about_vision",
          start: "center bottom",
          end: "center center",
          scrub: 1,
          // markers: true,
        }
      });
      aboutVision_tl
        .from("#contact_hand", { duration: 0.7, x: -100, y: -100, ease: "back.out(1.2)" })
        .from(".vision-item", { duration: 0.3, opacity: 0, scale: 0, stagger: 0.5, ease: "back.out(1.3)" }, '<-=0')
        .fromTo("#keyboard_enter_top", { y: 8 }, { y: 13 })
        .from("#keyboard_enter", { duration: 0.5, opacity: 0.5, ease: "back.out(1.7)" })
        .from(".arrow-top", { delay: 0.3, duration: 0.5, opacity: 0, y: 20, stagger: 0.5 })
        .to("#contact_hand", { duration: 0.5, x: -10, y: -10 })
        .to("#keyboard_enter_top", { y: 9 }, '<-=0')
    });
  }
});

$(document).ready(function () {
  if ($('.frame').data('page') == 'showcases') {
    const showcaseBannerAni = gsap.timeline();
    showcaseBannerAni
      .from(".showcase-hand-img", { delay: 0.1, duration: 0.5, y: 0, x: -180, ease: 'circ.out' })
      .from("#showcaseContent", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from("#showcaseVideo", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from("#showcaseVideo_bg", { duration: 0.3, ease: 'circ.out', attr: { fill: '#C4DCEE',}})
      .from("#showcaseVideo_icon", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from(".showcase_bn_arrow", { delay: 0.5, duration: 0.5, opacity: 0, y: -20, stagger: 0.5, repeat: -1, repeatDelay: 3, })
  }
});

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';


Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  if ($('.show-in-left').length) {
    gsap.set(".show-in-left", { opacity: 0, x: -40 });
    ScrollTrigger.batch(".show-in-left", {
      onEnter: function onEnter(batch) {
        gsap.to(batch, { ease: 'back.out(1.5)', opacity: 1, x: 0, stagger: 0.3 });
      },
    });
  }
  // transparent header
  let $header_h = $('.header').outerHeight();
  if ($(window).scrollTop() > $header_h) {
    $('.header').addClass("min-header");
  } else {
    $('.header').removeClass("min-header");
  }
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > $header_h) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  });

  $('.header-mobile-menu').on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $('.header-menu-outer').addClass('hidden');
      setTimeout(() => {
        $('.header-menu-outer').removeClass('active hidden');
      }, 350);
    }else {
      $(this).addClass('active');
      $('.header-menu-outer').addClass('active');
    }
  });

  $('.menu-link').click(function(e) {
      $(this).parent().toggleClass('active').siblings().removeClass('active');
  });

  // table responsive
  if ($('.custom-editor table').length >= 1) {
    $('.custom-editor table').wrap('<div class="table-responsive"/></div>');
  }

  // tab
  $('.tab[data-tab]').on('click', function () {
    var tabPanes = $(this).data('tabs');
    var ID = $(this).data('tab');
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $("[data-tab-panes=" + tabPanes + "]").find("[data-tab-pane=" + ID + "]").addClass('is-active').siblings().removeClass('is-active');
  });

  // showcase
  var showcaseSlider = new Swiper('#showcaseSlider', {
    effect: 'fade',
    autoplay: {
      delay: 6000,
    },
    speed: 1500,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

});

window.addEventListener("load", () => {
  let urlHash = window.location.href.split("#")[1],
    scrollElem = document.querySelector("#" + urlHash);
  if (scrollElem) {
    gsap.to(window, { scrollTo: scrollElem });
  }
});

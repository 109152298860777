$(document).ready(function () {
  if ($('.frame').data('page') == 'contact') {
    const contactBannerAni = gsap.timeline();
    contactBannerAni
      .from(".contact-hand-img", { delay: 0.1, duration: 0.5, y: 100, x: 80, ease: 'circ.out' })
      .from("#contact-phone", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from("#contact-meg", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from("#contact-meg-bg", { duration: 0.3, ease: 'circ.out', attr: { fill: '#4996C6',}})
      .from("#contact-meg2", { duration: 0.3, scale: 0, ease: 'circ.out'})
      .from("#contact-user", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from(".contact_bn_arrow", { delay: 0.5, duration: 0.5, opacity: 0, X: 20, stagger: 0.5, repeat: -1, repeatDelay: 3, })
  }
});

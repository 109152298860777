$(document).ready(function () {
  if ($('.frame').data('page') == 'posts') {
    const newsBannerAni = gsap.timeline();
    newsBannerAni
      .from("#news-meg", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from("#news-align", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from(".news-hand-img", { delay: 0.1, duration: 0.5, y: 180, x: 100, ease: 'circ.out' })
      .from("#news-file", { duration: 0.3, scale: 0, ease: 'circ.out' })
      .from(".showcase_bn_arrow", { delay: 0.5, duration: 0.5, opacity: 0, y: -20, stagger: 0.5, repeat: -1, repeatDelay: 3, })
  }
});
